// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-signup-js": () => import("./../../../src/pages/beta-signup.js" /* webpackChunkName: "component---src-pages-beta-signup-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-early-access-js": () => import("./../../../src/pages/early-access.js" /* webpackChunkName: "component---src-pages-early-access-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-request-js": () => import("./../../../src/pages/feature-request.js" /* webpackChunkName: "component---src-pages-feature-request-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-get-early-access-js": () => import("./../../../src/pages/get-early-access.js" /* webpackChunkName: "component---src-pages-get-early-access-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-more-js": () => import("./../../../src/pages/learn-more.js" /* webpackChunkName: "component---src-pages-learn-more-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-resources-data-driven-sales-strategy-tsx": () => import("./../../../src/pages/resources/data-driven-sales-strategy.tsx" /* webpackChunkName: "component---src-pages-resources-data-driven-sales-strategy-tsx" */),
  "component---src-pages-resources-how-to-visualize-data-js": () => import("./../../../src/pages/resources/how-to-visualize-data.js" /* webpackChunkName: "component---src-pages-resources-how-to-visualize-data-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tools-file-info-tsx": () => import("./../../../src/pages/tools/file-info.tsx" /* webpackChunkName: "component---src-pages-tools-file-info-tsx" */),
  "component---src-pages-trial-js": () => import("./../../../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

